/*
 * @Description: 混入
 * @Author: luocheng
 * @Date: 2022-07-13 15:14:29
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-08-18 10:58:59
 */
import Cooperation from '../Cooperation';
import SelectArchi from '@/views/newSecond/components/index/SelectArchi';
import eventBus from '@/plugins/eventBus';
import { setToken } from '@/utils/tools';
import { dataInterface } from '@/apis/data';
import { mapState } from 'vuex';
import UserActions from '../UserActions';
import APPCenter from '../appCenter/Index';
import appQrcode from '@/views/newSecond/components/appQrcode/Index'
import Apphome from '@/views/apphome/Index'
import { judgingArchiAPermission, judgingCustomLogic } from '@/utils/tools';

export default {
	components: {
		SelectArchi,
		UserActions,
		APPCenter,
		Cooperation,
		appQrcode,
    Apphome
	},
	data() {
		return {
      showapps:false,
			topNav: [],
			defaultLogo: require('@/assets/images/dev_logo.png'),
			// 应用中心
			showAppCenter: false,
			componentData: null,
			isUpdatePwd: true
		};
	},
	computed: {
		...mapState([
			'currentApp',
			'currentMenu',
			'projectEnvironment',
			'showAppCenterVisible',
			'systemConfig'
		]),
		systemLogo() {
			return this.systemConfig?.logo || this.defaultLogo;
		},
		needFiveParties() {
			return !!this.systemConfig?.need_five_parties;
		},
		needArchiInfo() {
			const { need_archi_info = 1 } = this.systemConfig?.need_archi_info;
			return !!need_archi_info;
		},
		needAppQrcode() {
      return !!this.systemConfig?.app_downLoad_Qrcode;
    },
	},
	created() {
		this.topNav = this.getMenuByLevel(this.currentApp?.app_orign_data[0]?.children_list || [], 2, [])
		this.setCurrentMenu(this.$route.path);
		this.isUpdatePwd = localStorage.getItem('updatePwd') == '0' && this.systemConfig.force_password;
	},
	mounted() {
		if (this.showAppCenterVisible) {
			this.$nextTick(() => {
				if (this.componentData && this.componentData.length) {
					this.onAPPCenter()
				}
			})
		}
	},
	watch: {
		$route: {
			handler() {
				this.showAppCenter = false;
			},
			immediate: true,
			deep: true
		},
		currentApp: {
			handler() {
				this.topNav = this.getMenuByLevel(this.currentApp?.app_orign_data?.[0]?.children_list || [], 2, [])
				this.setCurrentMenu(this.$route.path);
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		judgingArchiAPermission,
		judgingCustomLogic,
		/**
		 * @desc: 切换模块
		 * @param {Object} menuData
		 * @param {Boolean} inInit 初始化
		 * @param {Boolean} directClick 是否为直接点击tab
		 */
		async onTopNav(level, menuData, directClick = false) {
      this.showapps = false;
			// 跳转页面
			// console.log(menuData, '----menuData-----')
			if (menuData.menu_type !== 2) {
				const { menu_type = '', menu_path = '', link = '', link_type = '', page_uuid = '' } = menuData;
				/* 避免菜单因为点击切换民生罗铁  导致菜单丢失 */
				if (this.$route.path !== menu_path && menu_path.indexOf('dataview/show') !== -1) {
					window.open(menu_path.indexOf('http') === 0 ? menu_path : window.origin + menu_path);
					return
				}
				if (menu_type === 0) {
					// 页面
					if (this.$route.path !== menu_path) {
						this.$router.push({
							path: menu_path,
							query: {
								// pageId: pageObj.page_id, 暂时移除页面ID
								pageUUID: page_uuid
							}
						});
					}
				} else if (menu_type === 1) {
					// 跳转链接
					let linkUrl = link;
					if (link === 'DATAVIEW') {
						// 大屏
						linkUrl = await this.getDataViewUrl();
					}
					if (!linkUrl) {
						this.$message.error('出错了，请重试!');
						return false;
					}
					if (link_type === '_blank') {
						window.open(linkUrl);
					} else if (link_type === '_self') {
						window.location.href = linkUrl;
					} else {
						window.open(linkUrl);
					}
				} else if (menu_type === 3) {
					// 暂时弃用
				} else if (menu_type === 4) {
					// 仅跳转页面 微应用或某些基座内特殊页面
					if (this.$route.path !== menu_path) {
						this.$router.push({
							path: menu_path
						});
					}
				}
			} else if (directClick) {
				// 分组的情况且直接点击tab需要执行默认跳转
				// 若当前分组存在首页配置则跳转到首页
				const indexObj = this.getGroupIndex(menuData.children_list);
				// console.log(indexObj, '---indexObj-----', menuData)
				// menuData.children_list.forEach(ele => {
				// 	console.log(ele.is_index, '---e', ele.name, ele)
				// })
				if (!indexObj) {
					this.$message.warning('无效路径或无有效首页!');
					return;
				}
				this.goRoute(indexObj);
			}
			if (menuData.menu_type !== 1) {
				// 对于二级菜单
				this.$store.commit('setCurrentMenu', {
					level,
					menuData
				});
			}
		},
		/**
		 * @desc: 获取大屏默认绑定
		 */
		getDataViewUrl() {
			const loading = this.$loading();
			return new Promise((resolve, reject) => {
				dataInterface({}, '/api/dataview/getDefault', 'GET').then((res) => {
					if (res.status === 200 && res?.data?.data) {
						resolve(`${process.env.VUE_APP_BASE_URL}pc/dataview/show/${res.data.data}`)
					} else {
						this.$message.info('暂未配置大屏!');
						reject('')
					}
					loading.close();
				}).catch(err => {
					console.log(err, '-----------');
					reject('');
					loading.close();
				})
			});
		},
		/**
		 * @desc: 退出登录
		 */
		onQuit() {
			// 移除路由文件重新获取一次
			setToken('');
			sessionStorage.clear();
			localStorage.clear();
			// 刷新数据
			sessionStorage.removeItem('navHistoryList');
			this.$store.commit('setNavHistory', []);
			this.$store.commit('setTargetGlobalTab', '');
			this.$store.commit('setCurrentApp', null)
			this.$store.commit('setCurrentMenu', null)
			this.$store.commit('setAppIndexMenu', null)
			this.$router.replace({
				name: 'Login'
			});
			eventBus.$emit('updateRoute');
		},
		/**
		 * @desc: 展开选择应用
		 */
		onFuture() {
			this.$message({
				showClose: true,
				message: '功能开发中，敬请期待...'
			});
		},
		/**
		 * @desc: 根据等级获取菜单
		 * @param {Array} list 菜单列表
		 * @param {Number} level 等级
		 * @param {Array} result 结果
		 */
		getMenuByLevel(list, level, result = []) {
			for (let i = 0, len = list.length; i < len; i++) {
				if (list[i].level === level) {
					result.push(list[i]);
				}
				if (list[i].children_list && list[i].children_list.length) {
					result = this.getMenuByLevel(list[i].children_list, level, result);
				}
			}
			return result;
		},
		/**
		 * @desc: 设置当前的顶部或应用栏
		 * @param {String} path
		 */
		setCurrentMenu(path) {
			const originData = this.currentApp?.app_orign_data || [];
			if (!originData || !originData.length) {
				return;
			}
			const pathList = originData[0]?.children_list || [];
			// 应用
			for (let i = 0; i < pathList.length; i++) {
				const item = pathList[i];
				if (item.level === 2) {
					continue;
				}
				// 三级
				if (item.menu_path === path) {
					this.onTopNav(1, this.currentApp);
					return;
				}
				// 四级
				if (item.children_list && item.children_list.find(ele => ele.menu_path === path)) {
					this.onTopNav(1, this.currentApp);
					return;
				}
				// 五级
				if (item.children_list && item.children_list.length) {
					for (let j = 0; j < item.children_list.length; j++) {
						const childFour = item.children_list[j]
						if (childFour.menu_path === path || childFour.children_list.find(ele => ele.menu_path === path)) {
							this.onTopNav(1, this.currentApp);
							return;
						}
					}
				}
			}
			// 二级菜单
			for (let i = 0; i < this.topNav.length; i++) {
				const item = this.topNav[i];
				// 二级
				if (item.menu_path === path) {
					this.onTopNav(2, item);
					return;
				}
				// 三级
				if (item.children_list && item.children_list.find(ele => ele.menu_path === path)) {
					this.onTopNav(2, item);
					return;
				}
				// 四级 五级
				if (item.children_list && item.children_list.length) {
					for (let j = 0; j < item.children_list.length; j++) {
						const childFour = item.children_list[j]
						if (childFour.menu_path === path || childFour.children_list.find(ele => ele.menu_path === path)) {
							this.onTopNav(2, item);
							return;
						}
						if (childFour.children_list && childFour.children_list.length) {
							for (let k = 0; k < childFour.children_list.length; k++) {
								const childFive = childFour.children_list[k];
								if (childFive.menu_path === path || childFive.children_list.find(ele => ele.menu_path === path)) {
									this.onTopNav(2, item);
									return;
								}
							}
						}
					}
				}
			}
		},
		/**
		 * @desc: 跳转首页
		 */
		onHome() {
			this.$store.commit('setCurrentMenu', null);
			// 清空历史记录
			this.$store.commit('setAppNavHistory', {
          origin: 14,
          history: []
        });
			this.$router.replace('/second-screen');
		},
		/**
		 * @desc: 应用中心
		 */
		onAPPCenter(e) {
      // 应用中心页面不再是必选项
			// if (!this.componentData || !this.componentData.length) {
			// 	this.$message.warning('获取应用列表失败，请重试！');
			// 	return;
			// }
			if (!this.showAppCenterVisible) {
				e.stopPropagation();
				e.preventDefault();
			}
      this.showapps = true;
			this.showAppCenter = !this.showAppCenter;
			// 从第二屏跳转过来  只会触发一次自动打开应用中心浮层  触发后就关闭自动打开应用浮层
			this.$store.commit('setShowAppCenter', false);
		},
		/**
		 * @desc: 点击顶部
		 */
		onTopBar(e) {
			e.preventDefault();
			e.stopPropagation();
			if (e.target?.alt === '应用中心') {
				if (!this.componentData || !this.componentData.length) {
					this.$message.warning('获取应用列表失败，请重试！');
					return;
				}
				this.showAppCenter = true;
			} else {
				this.showAppCenter = false;
			}
		},
		/**
		 * @desc: 获取点击的应用
		 * @param {Object} data
		 */
		onUpdateAPPData(data) {
			this.componentData = data;
			if (this.showAppCenterVisible) {
				this.onAPPCenter()
			}
		}
	}
}