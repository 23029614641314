<!--
 * @Description: 菜单列表
 * @Author: luocheng
 * @Date: 2021-10-30 13:52:21
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-05-31 18:58:19
-->
<template>
	<article
		class="child-nav child-nav-cv 123"
    :class="[themeClassName]"
		:style="{
			width: showMenuList && menuList.length ? '180px' : '0px',
			padding: showMenuList && menuList.length ? '0px' : '0px'
		}"
	>
		<header class="child-header">
			<div class="name">
				<img src="@/assets/images/menu-icon.png" alt="">
				<el-tooltip class="item" :content="menuName" placement="top" v-if="menuName && menuName.length > 6">
					<h4 class="hidden-part">{{ menuName }}</h4>
				</el-tooltip>
				<h4 v-else>{{ menuName }}</h4>
			</div>
		</header>
		<article class="menu-box" @scroll="closeMenu">
			<div class="menu-list" v-if="menuList && menuList.length">
				<!-- 菜单默认收起@忠哥 -->
				<!-- :default-openeds="defaultOpened" -->
				<!-- eslint-disable -->
				<el-menu
					default-active=""
					class="menu-main"
					:default-openeds="defaultOpened"
				>
					<!-- 带下级 -->
					<!-- :background-color="'#ffffff'"
					:text-color="'#303133'"
					:active-text-color="'#1eb2fe'" -->
					<el-submenu
						v-if="item.menu_type === 2
							&& !item.is_hide
							&& judgingArchiAPermission(item.archi_limit, item.permissions)
							&& judgingCustomLogic(item.custom_logics)
						"
						:index="item.menu_path"
						v-for="item in menuList"
						:key="item.id"
					>
						<template slot="title">
							<i class="group-icon" :class="item.icon" v-if="item.icon && item.icon.toString().indexOf('el-icon') === 0"></i>
							<i v-else class="group-icon" :class="['iconfont', item.icon || 'iconyeqian']"></i>
							<span class="group-title" :title="item?.name?.length >= 8 ? item.name : ''">{{ item.name }}</span>
						</template>
						<span v-for="child in item.children_list" :key="child.id">
							<el-menu-item
								:index="child.menu_path || child.link"
								@click="onItem(child)"
								@contextmenu="onContextMenu(child, $event)"
								v-if="!child.is_hide
									&& judgingArchiAPermission(child.archi_limit, child.permissions)
									&& judgingCustomLogic(child.custom_logics)
								"
								:class="{
									'is-active': isCurrentActive(child),
									'without-active': !isCurrentActive(child)
								}"
								:data-config="JSON.stringify(child)"
							>
								<template slot="title">
									<i :class="['item-icon', child.icon]" v-if="child.icon && child.icon.toString().indexOf('el-icon') === 0"></i>
									<i v-else :class="['item-icon', 'iconfont', child.icon || 'iconyeqian']"></i>
									<span :title="child?.name?.length >= 8 ? child.name : ''">{{ child.name }}</span>
								</template>
							</el-menu-item>
						</span>
					</el-submenu>
					<!-- 不带下级 -->
					<el-menu-item
						v-else-if="!item.is_hide
							&& judgingArchiAPermission(item.archi_limit, item.permissions)
							&& judgingCustomLogic(item.custom_logics)
						"
						:index="item.menu_path || item.link"
						@click="onItem(item)"
						@contextmenu="onContextMenu(item, $event)"
						:data-config="JSON.stringify(item)"
						:class="{
							'is-active': isCurrentActive(item),
							'without-active': !isCurrentActive(item)
						}"
					>
						<i :class="['item-icon', item.icon]" v-if="item.icon && item.icon.toString().indexOf('el-icon') === 0"></i>
						<i :class="['item-icon', 'iconfont', item.icon || 'iconyeqian']" v-else></i>
						<span slot="title" :title="item?.name?.length >= 8 ? item.name : ''">{{ item.name }}</span>
					</el-menu-item>
				</el-menu>
			</div>
		</article>
	</article>
</template>

<script>
import listMixin from './listMixin';

export default {
	name: 'MenuList',
	mixins: [listMixin]
}
</script>

<style lang="less" scoped>
@itemHeight: 32px;
.child-nav{
	width: 202px;
	box-sizing: border-box;
	padding: 20px 0;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	transition: all 0.2s;
	box-shadow: 2px 0px 4px -2px rgba(0, 0, 0, 8%);
	background: var(--el-color-white);
	user-select: none;
  .child-header {
		min-height: 60px;
		width: 100%;
		box-sizing: border-box;
		padding: 0 16px;
    .name {
			display: flex;
			width: 100%;
			box-sizing: border-box;
			padding: 20px 0 15px 0;
			border-bottom: 1px solid #f2f4f8;
			.iconfont {
				margin-right: 8px;
				font-size: 16px;
				line-height: 24px;
			}
			img {
				margin-top: 1px;
				height: 22px;
				width: 22px;
				margin-right: 8px;
			}
			.el-tooltip {
				flex: 1;
			}
			h4 {
				// flex: 1;
				min-height: 24px;
				line-height: 24px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				font-size: 14px;
				font-family: PingFangSC, PingFangSC-Medium;
				font-weight: 500;
				text-align: left;
				color: #343b4a;
				letter-spacing: 1px;
				// word-break: break-all;
				&.hidden-part {
					width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}
  }
  .menu-box {
		flex: 1;
		overflow: hidden;
		width: 100%;
		overflow-y: auto;
    .menu-list{
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      :deep(.el-menu) {
        width: 100%;
        border: none!important;
        .el-menu-item {
          height: @itemHeight;
          line-height: @itemHeight;
          text-align: left;
          margin-bottom: 4px;
          min-width: 182px!important;
          padding-right: 10px;
          display: flex;
          .item-icon{
            line-height: @itemHeight;
            margin-right: 10px;
          }
          span {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .el-submenu__title {
          height: @itemHeight;
          line-height: @itemHeight;
          text-align: left;
          margin-bottom: 4px;
					.group-icon {
            color: #303133;
						margin-right: 10px;
          }
          .group-title{
						width: 108px;
            line-height: @itemHeight;
            margin-right: 10px;
						overflow: hidden;
						display: inline-block;
						text-overflow: ellipsis;
						white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>
<style>
.navlist-popper {
	background: rgba(0, 0, 0, 0.6)!important;
	color: #fff!important;
	width: auto!important;
	min-width: 80px!important;
	padding: 5px!important;
	padding: 5px!important;
	font-size: 12px!important;
}
.navlist-popper > .popper__arrow {
	display: none;
}
</style>